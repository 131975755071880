import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
//import logo from '../testAssets/logo.png';
import headerItems from '../testAssets/Header.json';
import assetItem  from '../testAssets/Asset.json';

const Header = ({data}) => {
    console.log('DATA', data)

    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const serverBaseUrlAssets = process.env.REACT_APP_SERVER_BASE_URL_ASSETS;
    //const apiKey = process.env.REACT_APP_API_KEY;
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    //const headerCollectionId = "65fd6e8d237c7ccdfc5df5c8"
    //const siteId = "65fbff84777e6eaf166a3e0f"
    //const url = `${serverBaseUrl}${headerCollectionId}/items`;
    //const urlAssets = `${serverBaseUrlAssets}${siteId}/assets`;
    //const [headerItems, setHeaderItems] = useState([]);
    //const [assetsItem, setAssetsItem] = useState({})


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('HEADER ITEMS',response);
                //setHeaderItems(response.data.items)
                //console.log('HEADER ITEMS STATE', headerItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(urlAssets, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('ASSET ITEM',response);
                //setAssetsItem(response.data.items)
                //console.log('HEADER ITEMS STATE', assetsItem)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/

    return (
        <>
       
            <div className="relative bgcolor-navy w-full overflow-hidden">
                

                {/* Cerchi giallo e arancio posizionati assolutamente */}
                <img src={`${imageUrl}${data.yellowcircle?.path}`} alt="Yellow Circle" className="absolute w-20 sm:w-80 -top-5 sm:-top-56 left-0 sm:left-0" />
                <img src={`${imageUrl}${data.orangecircle?.path}`} alt="Orange Circle" className="absolute w-20 sm:w-60 -top-3 sm:-top-32 -right-10 sm:-right-24" />

                {/* Navbar con sfocatura */}
                <div className="backdrop-blur-3xl bg-white/5 py-4 sm:py-7 px-4 flex justify-between items-center">
                    {/* Logo non influenzato dalla sfocatura */}
                    <img src={`${imageUrl}${data.logo?.path}`} alt="Logo" className="w-20 sm:w-32 ml-5 sm:ml-5 lg:ml-40" />
            
                    {/* Pulsante "Talk to us" non influenzato dalla sfocatura */}
                    <a href={data.href} className="mr-5 sm:mr-5 lg:mr-40 text-sm sm:text-md text-center pearl bg-transparent">
                        {data.cta}
                    </a>
                </div>
        
                {/* Contenuti principali */}
                <div className="relative overflow-hidden sm:h-[650px] h-[200px] lg:h-[400px] xl:h-[650px]">
                    {/* Cerchio rosa posizionato assolutamente */}
                    <img src={`${imageUrl}${data.pinkcircle?.path}`} alt="Pink Circle" className="absolute w-20 sm:w-60 -bottom-14 sm:-bottom-44 -left-8 sm:-left-24" />
            
                    {/* Immagine della ragazza posizionata assolutamente */}
                    <img src={`${imageUrl}${data.image?.path}`} alt="Girl at Desk" className="absolute right-0 bottom-0 w-2/3 xxs:w-1/2 xs:w-[300px] sm:w-[450px] md:w-[600px] xl:w-[1000px]" />
            
                    {/* Testo principale */}
                    <div className="absolute bottom-28 xxs:bottom-10 left-10 xs:bottom-16 sm:bottom-80 md:bottom-96 sm:left-28 md:left-44 lg:bottom-20 lg:left-24 xl:bottom-40 xl:left-40" >
                        <h1 className="text-[15px] xxs:text-[20px] sm:text-[60px] lg:text-[50px] xl:text-[60px] pearl">{data.title}</h1>
                        <h2 className="text-[20px] xxs:text-[25px] sm:text-[80px] lg:text-[60px] xl:text-[80px] font-bold italic -mt-3 sm:-mt-8 metallic-effect">{data.title2}</h2>
                        <p className="text-[6px] xxs:text-[7px] sm:text-[25px] lg:text-[20px] xl:text-[25px] pearl">{data.title3}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header