import React, {useState, useEffect} from 'react';
import axios from 'axios';
import introDnaItems from '../testAssets/IntroDna.json';
import dnaItems from '../testAssets/Dna.json';
import introValuesItems from '../testAssets/IntroValues.json';
import valuesItems from '../testAssets/Values.json';
import DOMPurify from 'dompurify';


const Dna = ({data}) => {
    console.log('DNA DATA', data)
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const images =[
        `${imageUrl}${data.image?.path}`,
        `${imageUrl}${data.pinkcircle?.path}`,
        `${imageUrl}${data.orangecircle?.path}`,
        `${imageUrl}${data.yellowshape?.path}`,
    ]

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); 

        return () => clearInterval(interval); 
    }, [images.length]);

    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const introDnaCollectionId = "65fd9a3dd7188b3a5b1ceace"
    //const dnaCollectionId = "65fd6fae8b3d4cb057d22080"
    //const introValuesCollectionId = "65fd9a73132aed5b8e6020b2"
    //const valueCollectionId = "65fd9aa0b9cdc10a476e8a9d"
    //const url = `${serverBaseUrl}${introDnaCollectionId}/items`;
    //const url2 = `${serverBaseUrl}${dnaCollectionId}/items`;
    //const url3 = `${serverBaseUrl}${introValuesCollectionId}/items`;
    //const url4 = `${serverBaseUrl}${valueCollectionId}/items`;
    //const [introDnaItems, setIntroDnaItems] = useState([]);
    //const [dnaItems, setDnaItems] = useState([]);
    //const [introValuesItems, setIntroValuesItems] = useState([]);
    //const [valueItems, setValueItems] = useState([]);

    //valore direttamente dal json
    //const IntroDnaTitle = introDnaItems.items[0].fieldData.dnatitle
    //const IntroValuesTitle = introValuesItems.items[0].fieldData.titlevalues


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('INTRODNA ITEMS',response);
                //setIntroDnaItems(response.data.items)
                //console.log('INTRODNA ITEMS STATE', introDnaItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])


    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url2, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('DNA ITEMS',response);
                //setDnaItems(response.data.items)
                //console.log('DNA ITEMS STATE', dnaItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url3, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('INTROVALUES ITEMS',response);
                //setIntroValuesItems(response.data.items)
                //console.log('INTROVALUES ITEMS STATE', introValuesItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url4, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('VALUES ITEMS',response);
                //setValuesItems(response.data.items)
                //console.log('VALUES ITEMS STATE', valuesItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/

    return (
        <>
        <div className="overflow-hidden bgcolor-navy">
            <h2 className='metallic-effect ml-10 sm:ml-40 text-2xl sm:text-4xl font-bold'>{data.title}</h2>
            {/*<div className='flex flex-col-reverse lg:flex-row-reverse align-middle mt-10 ml-10 sm:ml-40 mr-24 sm:mr-72 gap-20 sm:gap-40'>
                    <div className="flex flex-col justify-center gap-10 backdrop-blur-3xl relative " >
                        <h2 className="text-2xl sm:text-3xl font-semibold pearl">{data.title2}</h2>
                        <p className='pearl text-sm sm:text-md boxvalue4' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></p>
                    </div>
                    <div className="flex flex-col justify-center gap-10 backdrop-blur-3xl relative">
                        <h2 className="text-2xl sm:text-3xl font-semibold pearl">{data.title3}</h2>
                        <p className='pearl text-sm sm:text-md boxvalue3' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg2)}}></p>
                    </div>
            </div>*/}
            <div className='flex flex-row justify-between items-center align-middle'>
                <div className='flex flex-col'>
                    <h2 className="pearl mt-20 ml-10 sm:ml-40 text-2xl sm:text-3xl font-semibold pearl">{data.title2}</h2>
                    <div className='flex justify-start ml-10  xxs:mt-5 xxs:ml-20 xxs:mb-5 sm:ml-24 gap-8   '>
                        <p className='sm:ml-24 pearl w-[200px] sm:w-[450px] text-sm sm:text-lg boxvalue4' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></p>
                    </div>
                    <h2 className="pearl  ml-10 sm:ml-40 text-2xl sm:text-3xl font-semibold pearl">{data.title3}</h2>
                    <div className='flex justify-start  ml-10  xxs:mt-5 xxs:ml-20 xxs:mb-5 sm:ml-24 gap-8  '>
                        <p className='sm:ml-24 pearl w-[200px] sm:w-[450px] text-sm sm:text-lg boxvalue3' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg2)}}></p>
                    </div>
                </div>
                <div className='hidden lg:flex justify-center items-center w-1/2  mt-20 pe-28' >
                    <img  src={images[currentImageIndex]} className=''  />
                </div>
            </div>
            {/*<h2 className="pearl mt-20 ml-10 sm:ml-40 text-2xl sm:text-3xl font-semibold pearl">{data.title2}</h2>
            <div className='flex justify-start mt-10 ml-10 xxs:mt-20 xxs:ml-20 sm:ml-24 gap-8   mb-40'>
                <p className='sm:ml-24 pearl w-[200px] sm:w-[450px] text-sm sm:text-lg boxvalue4' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></p>
            </div>
            <h2 className="pearl mt-20 ml-10 sm:ml-40 text-2xl sm:text-3xl font-semibold pearl">{data.title3}</h2>
            <div className='flex justify-start mt-10 ml-10 xxs:mt-20 xxs:ml-20 sm:ml-24 gap-8   mb-40'>
                <p className='sm:ml-24 pearl w-[200px] sm:w-[450px] text-sm sm:text-lg boxvalue3' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg2)}}></p>
            </div>*/}
            <h2 className='pearl mt-20 ml-10 sm:ml-40 text-2xl sm:text-3xl font-semibold pearl'dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg3)}}></h2>
            <div className='flex flex-col sm:flex-row sm:flex-wrap justify-center align-middle mt-10 ml-10 xxs:mt-20 xxs:ml-20 sm:ml-24 gap-8 w-1/2 sm:w-4/5 2xl:w-2/3 mb-40'>
                {data._children && data._children?.map((item) =>(
                    <>
                    <div key={item._id} className={`flex flex-col justify-start gap-4 pearl w-[200px] sm:w-[450px] text-sm sm:text-lg ${item.customclasscss} `}>
                        <p >{item.title}</p>
                        <p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.wysiwyg)}}></p>
                    </div>
                    </>
                    ) 
                )}
            </div>
        </div>
        </>
    )
}

export default Dna