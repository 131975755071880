import DynamicComponent from "./DynamicComponent";

const DynamicComponents = ({ componentsData }) => {
    return (
      <>
        {componentsData?.map((componentData, index) => (
          <DynamicComponent key={index} componentData={componentData} />
        ))}
      </>
    );
  };

  export default DynamicComponents
  