import React from 'react';
import Header from './Header';
import Hero from './Hero';
import Timeline from './Timeline';
import Dna from './Dna';
import Offices from './Offices';
import Journey from './Journey';
import PreFooter from './PreFooter';
import Footer from './Footer';
import Jobs from './Jobs';
import Jobs2 from './Jobs2';


const DynamicComponent = ({ componentData, }) => {
    switch (componentData?.component) {
        case 'header':
            //console.log('CHILDREN', componentData )
            return <Header data={componentData} />;
        case 'hero':
            return <Hero data={componentData} />;
        case 'timeline':
            return <Timeline data={componentData} />;
        case 'dna':
            return <Dna data={componentData} />;
        case 'offices':
            return <Offices data={componentData} />;
        case 'team':
            return <Journey data={componentData} />;
        case 'prefooter':
            return <PreFooter data={componentData} />;
        case 'footer':
            return <Footer data={componentData} />;
        case 'jobs':
            return <Jobs2 data={componentData} />;
        default:
            return null;
    }
};

export default DynamicComponent;
