import React, {useState, useEffect} from 'react';
import axios from 'axios';
import introTimelineItems from '../testAssets/IntroTimeline.json';
import timelineItems from '../testAssets/Timeline.json';
import DOMPurify from 'dompurify';


const Timeline = ({data}) => {
    console.log('TIMELINE DATA', data)
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const IntroTimeLineCollectionId = "65fd99f4ff603d5f1292b3a8"
    //const timeLineCollectionId = "65fd6f3e32e49fdc2895ecf2"
    //const url = `${serverBaseUrl}${IntroTimeLineCollectionId}/items`;
    //const url2 = `${serverBaseUrl}${timeLineCollectionId}/items`;
    //const [introTimeLineItems, setIntroTimeLineItems] = useState([]);
    //const [timeLineItems, setTimeLineItems] = useState([]);

    //valore direttamente dal json
    //const timelineTitle = introTimelineItems.items[0].fieldData.timelinetitle
    //const timelineImage = introTimelineItems.items[0].fieldData.timelineimage.url
    //const timelineShape = introTimelineItems.items[0].fieldData.orangeshape.url


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('INTROTIMELINE ITEMS',response);
                //setIntroTimeLineItems(response.data.items)
                //console.log('INTROTIMELINE ITEMS STATE', introTimeLineItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])


    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url2, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('TIMELINE ITEMS',response);
                //setTimeLineItems(response.data.items)
                //console.log('TIMELINE ITEMS STATE', timeLineItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/

    return (
        <>
        <div className="overflow-hidden bgcolor-navy relative h-full">
            <img className='absolute w-32 sm:w-60 -top-7 sm:-top-14 -left-10 sm:-left-20' src={`${imageUrl}${data.orangeshape?.path}`} />
            <div className='flex flex-col my-20 sm:my-60 align-middle'>
                <h2 className='metallic-effect ml-10 sm:ml-40 text-2xl sm:text-4xl font-bold'>{data.title}</h2>
                {/*<img className='w-full mt-5' src={timelineImage} />*/}
                <div className='line hidden xl:block bgcolor-yellow xl:w-full h-1 mt-5'></div>
                <div className='flex flex-col xl:flex-row justify-between align-middle ml-10 sm:ml-40 sm:mr-40 mr-10'>
                {data._children && data._children.map( (item) =>(
                    <>
                    <div className='flex flex-col items-start backdrop-blur-3xl relative blur-background'>
                        <div className='flex flex-col items-center'>
                            <div className='line bgcolor-yellow w-1 h-20 sm:h-8'></div>
                            <div className='rounded-full bgcolor-yellow w-8 h-8'></div>
                            <div className='pearl text-2xl sm:text-3xl sm:mt-1 xl:mt-5'>{item.title}</div>
                        </div>
                        <div className='flex flex-col w-60'>
                            <div className='pearl text-sm sm:text-lg sm:mb-5 xl:mb-0' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title2)}}></div>
                        </div>
                    </div>
                    </>
                    ) 
                )}
                </div>
                <div className='line bgcolor-yellow w-1 h-40 xl:hidden ml-16 sm:ml-48'></div>
            </div>
        </div>
        </>
    )
}

export default Timeline