import React, {useState, useEffect} from 'react'
import DOMPurify from 'dompurify';

const ModalOffer = ({data, hasLink, showModal, setShowModal}) => {
  console.log('DATA MODAL',data)
  
const closeModale = () =>{
  setShowModal(false)
}

const url = `https://seedtag-1.jobs.personio.com/job/${data.id}?language=en&display=en`

  
const getBgClass = (index) => {
  const classes = ['bgcolor-yellow', 'bgcolor-red', 'bgcolor-pink', 'bgcolor-orange'];
  return classes[index % classes.length];
};

  return (
    <>
    {showModal && data && (
      <div className="fixed inset-0 bg-white bg-opacity-10 flex items-center justify-center z-10">
        <div className='bgcolor-navy flex flex-col items-center pearl gap-4 p-4 sm:p-20 sm:w-11/12 h-5/6 rounded-3xl overflow-auto xs:text-xs text-[9px] w-[250px]'>
          <div className='text-left w-full'>
          <h4 className='font-bold'>Id Offer: {data.id}</h4>
          <h4 className="font-bold">Office:{data.office}</h4>
          {data.subcompany && (
            <>
            <h4 className='text-left font-bold'>Sub Company: {data.subcompany}</h4>
            </>
          )}
          <h4 className='font-bold'>Name: {data.name}</h4>
          {data.additionalOffices && data.additionalOffices.length > 0 && (
              <>
                <h4 className="font-bold text-left">Additional Offices:</h4>
                {data.additionalOffices.map((officeNode, index) => (
                  <div key={index} className="text-left flex flex-col">
                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(officeNode.replace(/\]\]>/g, '').trim()) }}></p>
                  </div>
                ))}
              </>
          )}
          {data.department && (<h4 className="font-bold">Department: {data.department}</h4>)}
          {data.schedule && (<h4 className="font-bold">Schedule: {data.schedule}</h4>)}
          {data.employmentType && (<h4 className="font-bold">Employment Type: {data.employmentType}</h4>)}
          {data.seniority && (<h4 className="font-bold">Seniority: {data.seniority}</h4>)}
          {data.yearsOfExperience && (<h4 className="font-bold">Years of Experience: {data.yearsOfExperience}</h4>)}
          {data.occupation && (<h4 className='font-bold'>Occupation: {data.occupation}</h4>)}
          {data.occupationCategory && (<h4 className='font-bold'>Occupation Category: {data.occupationCategory}</h4>)}
          {data.keywords.length > 0 && (
            <>
              <div className='flex flex-row gap-2'>
                <h4 className='font-bold'>Keywords:</h4>
                {Array.isArray(data.keywords) && data.keywords.map((keyword, kIndex) => (
                <span key={kIndex} className={`font-light text-[9px] text-black xs:text-xs border-1 p-1 xs:-p2 rounded-full ${getBgClass(kIndex)}`}>
                  {keyword}
                </span> 
                ))}
              </div>
            </>
          )}
          {data.createdAt && (<h4 className='font-bold'>Created At: {data.createdAt.slice(0,10)}</h4>)}
          </div>
          {data.jobDescriptions.map((desc, index) => (
            <div key={index} className="text-left">
              <h4 className="font-bold">{desc.name}</h4>
              <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(desc.value.replace(/\]\]>/g, '').trim()) }}></p>
            </div>
          ))}
          <div className='flex flex-row justify-center items-center gap-3 mt-4'>
            {hasLink && (
              <button
              className='bgcolor-yellow w-20 rounded-full p-2 text-black'
              onClick={() => window.open(url, '_blank')}
            >
              Apply
            </button>
            )}
            <button className='bgcolor-yellow w-20 rounded-full p-2 text-black' onClick={closeModale}>Close</button>
          </div>
        </div>
      </div>
    )}
  </>
  )
}

export default ModalOffer