import React, {useState, useEffect} from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Timeline from './components/Timeline';
import Dna from './components/Dna';
import Journey from './components/Journey';
import PreFooter from './components/PreFooter';
import Footer from './components/Footer';
import Offices from './components/Offices';
import Offices2 from './components/Offices2';
import DynamicComponent from './components/DynamicComponent';
import DynamicComponents from './components/DynamicComponents';
import axios from 'axios';

function App() {
  //REACT_APP_SERVER_BASE_URL2 chiamata a getcockpit REACT_APP_API_KEY2 chiave getcockpit
  const [componentsData, setComponentsData] = useState(null);
  const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL2;
  const apiKey = process.env.REACT_APP_API_KEY2;
  const url = `${serverBaseUrl}/content/tree/seedtagcomponents`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            "api-key": apiKey
          }
        });
        setComponentsData(response.data);
        console.log('COMPONENT DATAAA', response.data)
      } catch (error) {
        console.error("There was an error fetching the data", error);
        console.log(error)
      }
    };

    fetchData();
  }, [serverBaseUrl, apiKey, url]);
  return (
    <>
    {/*
        <Header />
        <Hero />
        <Timeline />
        <Dna />
        <Offices />
        {/*<Offices2 />
        <Journey />
        <PreFooter />
        <Footer />
    */}
    <DynamicComponents componentsData={componentsData} />
    </>
  );
}

export default App;
