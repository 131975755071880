import React, {useState, useEffect} from 'react'
import Modal from './Modal'


const Offices = ({data}) => {
    /*const addresses = [
        {
            city: "Madrid",
            street:"Calle Marqués de Valdeiglesias, 6. Teléfono - 912909984",
        },
        {
            city: "Barcelona",
            street:"Avenida Diagonal 601, 8a Planta, 08028",
        },
        {
            city: "Paris",
            street:"35-37 rue Beaubourg 75003 Paris Fotos ofi Paris",
        },
        {
            city: "Milano",
            street:"Ripa di Porta Ticinese 63/A Milano 20143 Italy",
        },
        {
            city: "London",
            street:"LONDON 3rd & 4th Floor 67 Farringdon Road, EC1M 3JB London EC1N 2SW",
        },
        {
            city: "Amsterdam",
            street:"Amsterdam Jeroen Wirz John M. Keynesplein 12-46, G3K3 1066 EP, Amsterdam The Netherlands",
        },
        {
            city: "Munich",
            street:"Dachauer Str 15a 80335 Munich Germany",
        },
        {
            city: "Düsseldorf",
            street:"Düsseldorf Derendorfer Allee 6, 40476 Düsseldorf",
        },
    ]

    const addresses2 = [
        "Ciudad de Mexico",
        "São Paulo",
        "Bogotá",
        "Dubai",
        "Delaware",
        "Dover DE",
        "New York",
        "Miami"
    ]

    const doubledAddresses = [...addresses, ...addresses,];
    const doubledAddresses2 = [...addresses2, ...addresses2];

    */

    console.log('INDIRIZZI', data._children)
    const children = data._children;
    console.log('NUMERO INDIRIZZI', children.length)
    const halfLength = Math.ceil(children.length / 2);
    console.log('metà indirizzi', halfLength)
    const firstHalf = children.slice(0, halfLength);
    console.log('prima metà', firstHalf)
    const secondHalf = children.slice(halfLength);
    console.log('seconda metà', secondHalf)
    const doubledFirstHalf = [...firstHalf, ...firstHalf, ...firstHalf, ...firstHalf];
    const doubledSecondHalf = [...secondHalf, ...secondHalf, ...secondHalf, ...secondHalf];


    const [isPaused, setIsPaused] = useState(false);
    const [isPaused2, setIsPaused2] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [hasLink, setHasLink] = useState(false);

    const getColorClass = (index) => {
        const classes = ['bgcolor-yellow', 'bgcolor-red', 'bgcolor-pink', 'bgcolor-orange'];
        return classes[index % classes.length];
    };

    const handleShowModal = (address) =>{
        setShowModal(true)
        setSelectedAddress(address);
        console.log('SELECTED ADDRESS',selectedAddress)
        setHasLink(true)
    }
  
  return (
    <>
    <div className='py-20 bgcolor-navy text-black  text-5xl w-full'>
    <h2 className='pearl ml-10 sm:ml-40 text-2xl sm:text-4xl font-bold mb-20'>Offices</h2>
        <div className="offices-container">
            <div className={`cursor-pointer track ${isPaused ? "paused" : ""}`}>
                {doubledFirstHalf.map((address, index) => (
                    <div key={`a-${index}`} className={`address ${getColorClass(index)}`} 
                    onMouseEnter={() => setIsPaused(true)} 
                    onMouseLeave={() => setIsPaused(false)}
                    onClick={() => handleShowModal(address)}
                    >{address.title}</div>
                ))}
            </div>
        </div>
        <div className="offices-container">
            <div className={`cursor-pointer track2 ${isPaused2 ? "paused" : ""}`}>
                {doubledSecondHalf.map((address, index) => (
                    <div key={`b-${index}`} className={`address ${getColorClass(index)}`} 
                    onMouseEnter={() => setIsPaused2(true)} 
                    onMouseLeave={() => setIsPaused2(false)}
                    onClick={() => handleShowModal(address)}
                    >{address.title}</div>
                ))}
            </div>
        </div>
    </div>
    {showModal && (
        <Modal
            data={selectedAddress}
            hasLink={hasLink}
            setShowModal={setShowModal}
            showModal={showModal}
        />
    )}
    </>
  )
}

export default Offices