const FilterSelect = ({ options, onChange, label, value }) => (
    <div className="flex flex-row justify-center items-center gap-4 text-white w-10 xs:w-auto ">
       <label className="xs:text-[18px] text-[8px]">{label}</label>
       <select className="text-black rounded-full xs:text-[18px] text-[8px]" onChange={onChange} value={value}>
         <option value="">All</option>
         {options.map((option, index) => (
           <option key={index} value={option}>
             {option}
           </option>
         ))}
       </select>
    </div>
   );

export default FilterSelect