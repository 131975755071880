import React, {useState, useEffect} from 'react';
import axios from 'axios';
import journeyItems from '../testAssets/Journey.json';
import teamItems  from '../testAssets/Team.json';
import Modal from './Modal';
import DOMPurify from 'dompurify';

const Journey = ({data}) => {
    console.log('TEAM DATA', data)
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    const [showModal, setShowModal] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState({});
    const [hasLink, setHasLink] = useState(false);
    const [hoveredItems, setHoveredItems] = useState([]);

    const handleShowModal = (item) =>{
        setShowModal(true)
        setSelectedTeam(item);
        setHasLink(false)
    }

    const handleMouseEnter = (itemId) => {
        setHoveredItems(prevItems => [...prevItems, itemId]);
    };

    const handleMouseLeave = (itemId) => {
        setHoveredItems(prevItems => prevItems.filter(id => id!== itemId));
    };
    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const journeyCollectionId = "65fd6fc41bd638e99638522a"
    //const teamCollectionId = "65fd706ac7711f3326b4416b"
    //const url = `${serverBaseUrl}${journeyCollectionId}/items`;
    //const url2 = `${serverBaseUrl}${teamCollectionId}/items`;
    //const [journeyItems, setJourneyItems] = useState([]);
    //const [teamItem, setTeamItem] = useState([])


    //valore direttamente dal json
    //const journeyTitle = journeyItems.items[0].fieldData.title
    //const journeyOrgangeCircle = journeyItems.items[0].fieldData.organgecircle.url
    //const journeyYellowCircle = journeyItems.items[0].fieldData.yellowcircle.url
    //const journeyPattern = journeyItems.items[0].fieldData.pattern.url


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('JOURNEY ITEMS',response);
                //setJourneyItems(response.data.items)
                //console.log('JOURNEY ITEMS STATE', journeyItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url2, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('TEAM ITEM',response);
                //setAssetsItem(response.data.items)
                //console.log('TEAM ITEMS STATE', teamItem)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/


    return (
        <>
        <div className="relative bgcolor-navy w-full">
            {/*da mobile non potendo togliere l'overflow quando uso le shape colorati mi sbacchetta ai lati */}
            {/*<img src={`${imageUrl}${data.orangecircle?.path}`} alt="Orange Circle" className="absolute hidden sm:block sm:w-20 sm:top-5 sm:left-0" />
            <img src={`${imageUrl}${data.yellowcircle?.path}`} alt="Yellow Circle" className="absolute hidden sm:block sm:w-20 sm:-bottom-10 sm:-left-12" />
            <img src={`${imageUrl}${data.yellowcircle?.path}`} alt="Yellow Circle 2" className="absolute hidden sm:block sm:w-32 sm:bottom-40 sm:-right-10" />*/}
            
            <img src={`${imageUrl}${data.orangecircle?.path}`} alt="Orange Circle" className="absolute hidden w-20 lg:block sm:w-40 top-10 sm:top-10 -left-10 sm:-left-20" />
            <img src={`${imageUrl}${data.yellowcircle?.path}`} alt="Yellow Circle" className="absolute hidden w-20 lg:block sm:w-32 sm:-bottom-10 sm:-left-12" />
            <img src={`${imageUrl}${data.yellowcircle?.path}`} alt="Yellow Circle 2" className="absolute hidden w-20 lg:block sm:w-60 top-10 sm:top-72 -right-5 sm:-right-40" />


            <div className="relative backdrop-blur-3xl bg-white/5">
                <h2 className='pearl ml-10 sm:ml-40 w-20 text-2xl sm:text-4xl font-bold absolute -top-6'>{data.title}</h2>

                <div className="flex justify-center items-center overflow-hidden">
                    {/*<img src={journeyPattern} alt="Pattern" className="object-fill pt-20 sm:pt-40" />*/}
                    <div className='flex flex-col flex-wrap sm:flex sm:flex-row justify-center gap-5 sm:gap-5 py-40 sm:py-20 background-container'
                                    /*style={{
                                        background: `url(${imageUrl}${data.pattern?.path}) no-repeat center`,
                                        backgroundSize: "contain",
                                    }}*/
                                    style={{
                                        backgroundImage: `url(${imageUrl}${data.pattern?.path})`
                                    }}>
                    {data._children && data._children.map( (item, index) =>(
                        <>
                       <div
                            //onClick={() => handleShowModal(item)}
                            onMouseEnter={() => handleMouseEnter(item._id)}
                            onMouseLeave={() => handleMouseLeave(item._id)}
                            key={item._id}
                            className={`cursor-pointer flex flex-row items-center align-middle sm:flex-col sm:items-center rounded-full overflow-hidden sm:my-20 w-40 h-20 sm:w-16 sm:h-52 lg:w-44 lg:h-1/3 relative  ${item.customclasscss} 
                            ${index % 2 === 0 ? "sm:mt-10 lg:mt-40" : "sm:mb-10 lg:mb-40"} `} >
                            <div className='flex flex-row justify-between gap-5 items-center sm:flex sm:flex-col'>
                                <div className="text-center lg:pt-20 px-4 sm:pt-5 sm:px-3 lg:px-0">
                                    <p className="text-[6px] sm:text-[8px] lg:text-[16px] font-bold">{item.title}</p>
                                    <p className="text-[6px] sm:text-[8px] lg:text-sm font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item.title2)}}></p>
                                </div>
                                {hoveredItems && hoveredItems.includes(item._id) && (
                                    <button
                                        style={{ position: 'absolute', bottom: '10%', left: '50%', transform: 'translateX(-50%)' }}
                                        className={`z-20 ${index === 2 ? 'bgcolor-pink' : 'bgcolor-yellow'} w-10 p-1 text-[5px] sm:p-1 sm:w-10 sm:text-[6px] lg:w-20 lg:p-2 lg:text-lg rounded-full text-black `}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleShowModal(item);
                                        }}
                                    >
                                        {item.cta}
                                    </button>
                                )}
                                <img
                                className="relative object-contain h-40 lg:h-auto pt-10 sm:pt-0 overflow-hidden"
                                src={`${imageUrl}${item.image?.path}`}
                                alt={item.title}
                                />
                            </div>
                        </div>
                        </>
                    ) 
                    )}
                    </div>
                </div>
            </div>
        </div>
        {showModal && (
        <Modal
            data={selectedTeam}
            hasLink={hasLink}
            setShowModal={setShowModal}
            showModal={showModal}
        />
    )}
        </>
    )
}

export default Journey