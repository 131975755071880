import React, {useState, useEffect} from 'react';
import axios from 'axios';
import preFooterItems from '../testAssets/PreFooter.json';
import DOMPurify from 'dompurify';

const PreFooter = ({data}) => {
    console.log('PREFOORTER DATA',data)
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const preFooterCollectionId = "65fd7092237c7ccdfc5fcd34"
    //const url = `${serverBaseUrl}${preFooterCollectionId}/items`;;
    //const [preFooterItems, setPreFooterItems] = useState([]);


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('PREFOOTER ITEMS',response);
                //setPreFooterItems(response.data.items)
                //console.log('PREFOOTER ITEMS STATE', preFooterItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/


    return (
        <>
        <div className="relative bgcolor-navy w-full h-96 sm:h-screen flex items-center justify-center overflow-hidden">
            {/*{preFooterItems.items && preFooterItems.items.map( (item) =>(*/}
                <>
            <img src={`${imageUrl}${data.yellowshape?.path}`} alt='Yellow Shape' className="absolute w-24 sm:w-64 -top-5 sm:-top-16 -left-10 sm:-left-48" />
            <div className='relative flex flex-col justify-center items-center gap-10'>
                <img src={`${imageUrl}${data.image?.path}`} alt='Logo Seedtag' className="absolute w-16 sm:w-24 md:w-30 -top-10 sm:-top-36 -left-0 sm:-left-0 md:-left-20 lg:-left-40" />
                <h1 className='pearl font-normal text-md sm:text-4xl'>{data.title}</h1>
                <a href={data.href} target="_blank" className='bgcolor-yellow w-40 sm:w-44 rounded-full text-md font-normal text-black py-2 text-center'>{data.cta}</a>
            </div>
            </>
            {/*}))}*/}
        </div>
        </>
    )
}

export default PreFooter