import React, {useState, useEffect} from 'react'
import DOMPurify from 'dompurify';

const Modal = ({data, hasLink, showModal, setShowModal}) => {
  console.log('DATA MODAL',data)
  
  const closeModale = () =>{
    setShowModal(false)
  }
  return (
    <>
    {showModal && (
    <div className="fixed top-0 left-0 w-screen h-screen bg-white bg-opacity-10 flex items-center justify-center z-10 overflow-y-auto">
      <div className='bgcolor-navy flex flex-col items-center pearl gap-4 p-10 rounded-3xl overflow-auto opacity-80 w-1/3'>
        <div className='text-center text-sm p-5' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></div>
        <div className='flex flex-row justify-center items-center gap-3'>
          {hasLink && data.href && (
            <a href={data.href} className='bgcolor-yellow w-20 rounded-full p-2 text-center text-black'>{data.cta}</a>
          )}
          <button className='bgcolor-yellow w-20 rounded-full p-2 text-black' onClick={closeModale}>Close</button>
        </div>
      </div>
    </div>
    )}
    </>
  )
}

export default Modal