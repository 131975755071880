import React, {useState, useEffect} from 'react';
import axios from 'axios';
import heroItems from '../testAssets/Hero.json';
import Accordion from './Accordion';
import DOMPurify from 'dompurify';


const Hero = ({data}) => {
    console.log('HERO DATA', data)
    const buttonClasses = ['bgcolor-yellow', 'bgcolor-orange', 'bgcolor-red'];
    const boxClasses = ['boxvalue1', 'boxvalue3', 'boxvalue4'];

    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const headerCollectionId = "65fd6ed4f8b5faf5332de86f"
    //const url = `${serverBaseUrl}${headerCollectionId}/items`;
    //const [heroItems, setHeroItems] = useState([]);
    const imageUrl = process.env.REACT_APP_IMAGE_URL;


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('HERO ITEMS',response);
                //setHeaderItems(response.data.items)
                //console.log('HEADER ITEMS STATE', headerItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/

    //ERRORE DI BATTIRURA SU "ORGANGESHAPE" NON RIESCO A CAMBIARLO

    return (
        <>
     {/*   {heroItems.items && heroItems.items.map( (item) =>(*/}
            <div className="relative bgcolor-navy font-medium overflow-hidden">

                {/* immagini posizionate assolutamente */}
                <img src={`${imageUrl}${data.pinkcircle?.path}`} alt="Pink Circle" className="absolute w-40 sm:w-60 -top-20 -left-24" />
                <img src={`${imageUrl}${data.pinkcircle?.path}`} alt="Pink Circle 2" className="absolute w-40 sm:w-72 bottom-60 sm:top-96 -right-16 sm:-right-52" />
                <img src={`${imageUrl}${data.orangecircle?.path}`} alt="Orange Circle" className="absolute w-40 sm:w-60 -top-28 -right-10" />
                {/*<img src={item.fieldData.orangecircle.url} alt="Orange Circle 2" className="absolute w-60 -bottom-40 -left-24" />*/}
                <img src={`${imageUrl}${data.organgeshape?.path}`} alt="Orange Shape" className="absolute w-40 sm:w-60 -bottom-5 -left-16" />
        
                {/* Paragrafi non influenzati dalla sfocatura */}
                {/*<div className='flex flex-col items-center backdrop-blur-3xl bg-white/5 overflow-hidden py-10 sm:py-40'>
                    <div className="w-1/2 sm:w-2/5 text:sm sm:text-lg pearl text-left bg-transparent" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></div>
                    <div className="my-5 w-1/2 sm:w-2/5 text:sm sm:text-lg pink text-left bg-transparent" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg2)}}></div>
                    <div className="w-1/2 sm:w-2/5 text:sm sm:text-lg pearl text-left bg-transparent" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg3)}}></div>
                </div>*/}
                <div className='flex flex-col items-center justify-center gap-1 backdrop-blur-3xl bg-white/5 overflow-hidden py-10 sm:py-40'>
                {data._children && data._children.map((accordion, index) => (
                        <Accordion 
                        //tyle={index % 2 === 0 ? "bg-white" : "bgcolor-pink"}
                        key={accordion._id} 
                        title={accordion.title} 
                        content={accordion.wysiwyg}
                        buttonClass={buttonClasses[index % buttonClasses.length]}
                        boxClass={boxClasses[index % boxClasses.length]}
                        />
                ))}
                   </div>
                {/*
                <div className='flex flex-col items-center backdrop-blur-3xl bg-white/5 overflow-hidden py-10 sm:py-40'>
                    <Accordion title={data.title} content={data.wysiwyg} />
                    <Accordion title={data.title2} content={data.wysiwyg2} />
                    <Accordion title={data.title3} content={data.wysiwyg3} />
                </div>
                */}
            </div>
      {/*}      ) 
        )}*/}
        </>
    )
}

export default Hero