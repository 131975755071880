import React from "react";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";
const geoUrl =
  "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json";

const Offices2 = () => {
    const offices =[
        { name: "Madrid Office", coordinates: [-3.703790, 40.416775] },
    ]
    
    return(
  <ComposableMap >
    <Geographies geography={geoUrl}>
      {({ geographies }) =>
        geographies.map(geo => <Geography key={geo.rsmKey} geography={geo} />)
      }
    </Geographies>
    {offices.map(({ name, coordinates }, i) => (
      <Marker key={i} coordinates={coordinates}>
        <circle r={10} fill="#F53" />
        <text textAnchor="middle" y={-15} style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}>
          {name}
        </text>
      </Marker>
    ))}
  </ComposableMap>
)};

export default Offices2
