import React, {useState, useEffect} from 'react';
import axios from 'axios';
import footerItems from '../testAssets/Footer.json';
import DOMPurify from 'dompurify';

const Footer = ({data}) => {
    console.log('FOORTER DATA',data)
    console.log('FOOTER CHILDS', data._children)
    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    //const serverBaseUrl = process.env.REACT_APP_SERVER_BASE_URL;
    //const apiKey = process.env.REACT_APP_API_KEY;
    //const footerCollectionId = "65fd70d74d42ce4e690c0de7"
    //const url = `${serverBaseUrl}${footerCollectionId}/items`;;
    //const [footerItems, setFooterItems] = useState([]);


    /*useEffect(() => {
        const fetchData = async () => {
      
            try {
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${apiKey}`
                    }
                });
                console.log('FOOTER ITEMS',response);
                //setFooterItems(response.data.items)
                //console.log('FOOTER ITEMS STATE', footerItems)
            } catch (error) {
            console.error("There was an error fetching the data", error);
            }  
        };
      
        fetchData();
    }, [])*/

    /*
            <div className="bgcolor-navy w-full flex items-center justify-center py-5">
                {footerItems.items && footerItems.items.map( (item) =>(
                <>
                <div className='w-full flex flex-col-reverse sm:flex-row justify-evenly items-center gap-10 sm:gap-44'>
                    <div className='flex flex-row gap-2'>
                        <a href={data.href} target="_blank">
                            <img src={`${imageUrl}${data.iconIg?.path}`} className='w-15' alt="Instagram"/>
                        </a>
                        <a href={data.href2} target="_blank">
                            <img src={`${imageUrl}${data.iconFb?.path}`} className='w-15' alt="Facebook"/>
                        </a>
                    </div>
                    <div className='flex flex-col relative'>
                        <div className='absolute line bgcolor-yellow w-10 h-[1px] mt-5 sm:-top-10 -top-10 left-10 '></div>
                        <p className='text-sm pearl object-contain' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg)}}></p>
                        <p className='text-sm pearl object-contain' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg2)}}></p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='text-xs pearl' dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.wysiwyg3)}}></p>
                    </div>
                </div>
                </>
            ))}
    </div>
    */


    return (
        <>
        <div className="bgcolor-navy w-full flex flex-col items-center justify-center py-5">
                    <>
                    <div className='sm:hidden flex justify-center items-center w-full'>
                        <div className='line bgcolor-yellow w-10 h-[1px] my-5 '></div>
                    </div>
                    <div className='w-full flex flex-col sm:flex-row justify-evenly items-center gap-5 sm:gap-44'>
                        <div className='flex flex-row gap-2'>
                            {data._children && data._children.map((child) => (
                                <>
                                {child.footerposition === 'left' && (
                                <a href={child.href} target="_blank">
                                    <img src={`${imageUrl}${child.image?.path}`} className='w-15' alt={child.href}/>
                                </a>
                                )}
                                </>
                            ))}
                        </div>
                        <div className='flex flex-col relative justify-center items-center'>
                            <div className='hidden sm:block absolute line bgcolor-yellow w-10 h-[1px] mt-5 -top-10 left-10 '></div>
                            {data._children && data._children.map((child) => (
                                <>
                                {child.footerposition === 'center' && (
                                <a href={child.href} target="_blank" className='text-sm pearl' alt={child.href}>{child.title}</a>
                                )}
                                </>
                            ))}
                        </div>
                        <div className='flex flex-col gap-2 justify-center items-center'>
                        {data._children && data._children.map((child) => (
                                <>
                                {child.footerposition === 'right' && (
                                <a href={child.href} target="_blank" className='text-xs pearl' alt={child.href}>{child.title}</a>
                                )}
                                </>
                            ))}
                        </div>
                    </div>
                    </>
        </div>
        </>
    )
}

export default Footer