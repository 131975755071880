import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const Accordion = ({ title, content, buttonClass, boxClass }) => {
  const [isOpen, setIsOpen] = useState(false);
  const sanitizedContent = DOMPurify.sanitize(content);

  return (
    <div className='w-1/2 sm:w-2/5 lg:w-full text-[14px] xs:text-md md:text-lg pearl text-left bg-transparent mt-5' >
      <div className={`cursor-pointer text-black flex flex-row justify-center items-center gap-2 rounded-full p-4 text-center lg:mr-72 lg:ml-72 ${buttonClass}`}  onClick={() => setIsOpen(!isOpen)}>
        <div className={`cursor-pointer text-black `}>
          {title}
        </div>
        <div>
          <FiChevronDown className={`text-black ${isOpen ? 'hidden' : ''}`} />
          <FiChevronUp className={`text-black ${!isOpen ? 'hidden' : ''}`} />
        </div>
      </div>
      {/*
      
    }
      {isOpen && (
        <div className="p-4 mt-5 boxvalue2 text-left rounded-md">
          <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </div>
      )}*/}
      <div
        className={`overflow-auto transition-all duration-1000 ease-in-out lg:mr-72 lg:ml-72 ${
          isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <div className={`p-4 mt-5 ${boxClass} text-left rounded-md`}>
          <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
        </div>
      </div>
    </div>
  );
};

export default Accordion;