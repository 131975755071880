import React, { useEffect, useState } from 'react';
import ModalOffer from './ModalOffer';
import DOMPurify from 'dompurify';
import FilterSelect from './FileterSelect';



const url = 'https://seedtag-1.jobs.personio.com/xml?language=en';

async function fetchXMLtoJSON(url) {
    try {
      const response = await fetch(url);
      const xmlText = await response.text();
      console.log('XMLTEXT',xmlText)
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlText, "application/xml");
      console.log('XMLDOC',xmlDoc)
      const positions = xmlDoc.getElementsByTagName("position");
      console.log('POSITIONS',positions)
      const jobs = Array.from(positions).map(position => {
        const jobDescriptions = Array.from(position.getElementsByTagName("jobDescription")).map(desc => {
            const name = desc.getElementsByTagName("name")[0].textContent;
            const value = desc.getElementsByTagName("value")[0].innerHTML; 
            return { name, value };
          });
        const additionalOfficesNode = position.getElementsByTagName("additionalOffices")[0];
        const additionalOffices = Array.from(additionalOfficesNode ? additionalOfficesNode.getElementsByTagName("office") : []).map(officeNode => officeNode.textContent);
        const keywordsNode = position.getElementsByTagName("keywords")[0];
        const keywords = keywordsNode ? keywordsNode.textContent.split(',').map(keyword => keyword.trim()) : [];
        return {
            id: position.getElementsByTagName("id")[0].textContent,
            subcompany: position.getElementsByTagName("subcompany")[0] ? position.getElementsByTagName("subcompany")[0].textContent : '' ,
            office: position.getElementsByTagName("office")[0].textContent,
            additionalOffices,
            department: position.getElementsByTagName("department")[0] ? position.getElementsByTagName("department")[0].textContent : '',
            recruitingCategory: position.getElementsByTagName("recruitingCategory")[0] ? position.getElementsByTagName("recruitingCategory")[0].textContent : '',
            name: position.getElementsByTagName("name")[0].textContent,
            jobDescriptions,
            employmentType: position.getElementsByTagName("employmentType")[0].textContent,
            seniority: position.getElementsByTagName("seniority")[0].textContent,
            schedule: position.getElementsByTagName("schedule")[0].textContent,
            yearsOfExperience: position.getElementsByTagName("yearsOfExperience")[0] ? position.getElementsByTagName("yearsOfExperience")[0].textContent : '',
            //keywords: position.getElementsByTagName("keywords")[0] ? position.getElementsByTagName("keywords")[0].textContent : '',
            keywords,
            occupation: position.getElementsByTagName("occupation")[0].textContent,
            occupationCategory: position.getElementsByTagName("occupationCategory")[0].textContent,
            createdAt: position.getElementsByTagName("createdAt")[0].textContent,
        };
      });
      console.log(jobs)
      return jobs;
    } catch (error) {
      console.error('Error fetching or parsing XML:', error);
      return [];
    }
  }
  
const Jobs2 = ({data}) => {
    console.log('JOB DATA', data)
    const [jobs, setJobs] = useState([]);
    //const [filteredJobs, setFilteredJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const jobsPerPage = 4;
    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;
    const [filters, setFilters] = useState({
        name: '',
        office: '',
        department: '',
        recruitingCategory: '',
        employmentType: '',
        seniority: '',
        schedule: '',
        yearsOfExperience: '',
    });
    const currentJobs = jobs
    .filter(job => {
        return Object.keys(filters).every(key => {
            if (!filters[key]) return true;
            return job[key].toLowerCase().includes(filters[key].toLowerCase());
        });
    })
    .slice(indexOfFirstJob, indexOfLastJob);

    const [totalPages, setTotalPages] = useState(0);

    const imageUrl = process.env.REACT_APP_IMAGE_URL;
    const [showModal, setShowModal] = useState(false);
    const [selectedOffer, setSelectedOffer] = useState({});

    const uniqueNames = [...new Set(jobs.map(job => job.name))];
    const uniqueOffices = [...new Set(jobs.map(job => job.office))];
    const uniqueDepartments = [...new Set(jobs.map(job => job.department))];
    const uniqueRecruitingCategories = [...new Set(jobs.map(job => job.recruitingCategory))];
    const uniqueEmploymentTypes = [...new Set(jobs.map(job => job.employmentType))];
    const uniqueSeniorities = [...new Set(jobs.map(job => job.seniority))];
    const uniqueSchedules = [...new Set(jobs.map(job => job.schedule))];
    const uniqueYearsOfExperience = [...new Set(jobs.map(job => job.yearsOfExperience))];

    useEffect(() => {
        const filteredJobs = jobs.filter(job => {
            return Object.keys(filters).every(key => {
                if (!filters[key]) return true;
                return job[key].toLowerCase().includes(filters[key].toLowerCase());
            });
        });
        setTotalPages(Math.ceil(filteredJobs.length / jobsPerPage));
        setCurrentPage(1);  
    }, [jobs, filters]); 
       
    const resetFilters = () => {
        setFilters({
           name: '',
           office: '',
           department: '',
           recruitingCategory: '',
           employmentType: '',
           seniority: '',
           schedule: '',
           yearsOfExperience: '',
        });
       };  

    const [hasLink, setHasLink] = useState(false);

    const handleShowModal = (job) =>{
        setShowModal(true)
        setSelectedOffer(job);
        setHasLink(true)
    }

    const getColorClass = (index) => {
        const classes = ['boxvalue1', 'boxvalue2', 'boxvalue3', 'boxvalue4'];
        return classes[index % classes.length];
    };

    const getBgClass = (index) => {
        const classes = ['bgcolor-yellow', 'bgcolor-red', 'bgcolor-pink', 'bgcolor-orange'];
        return classes[index % classes.length];
    };

    useEffect(() => {
        fetchXMLtoJSON(url).then(jobsData => {
            setJobs(jobsData);
            setTotalPages(Math.ceil(jobsData.length / jobsPerPage));
        });
    }, []);

    const nextPage = () => {
        setCurrentPage(currentPage => Math.min(currentPage + 1, totalPages));
    }

    const prevPage = () => {
        setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
    }

    const handleFilterChange = (event, filterKey) => {
        setFilters({
           ...filters,
           [filterKey]: event.target.value,
        });
    };

    return (

    <>
    <div className="relative bgcolor-navy overflow-hidden pt-20">
        <img src={`${imageUrl}${data.yellowshape?.path}`} alt='Yellow Shape' className="absolute w-24 sm:w-64 -top-5 sm:-top-16 -left-10 sm:-left-48" />
        <h2 className='metallic-effect ml-10 sm:ml-40 text-2xl sm:text-4xl font-bold'>{data.title}</h2>
        <div className='flex flex-col gap-10 justify-center items-center mr-40 ml-40'>
            <div className="flex flex-col justify-center items-center gap-5 mt-5">
                <div className="flex flex-col items-start gap-1 xs:flex xs:flex-row xs:flex-wrap xs:justify-center xs:items-center xs:gap-2 mt-5 ">
                        <FilterSelect
                            //options={jobs.map(job => job.name)}
                            options={uniqueNames}
                            onChange={event => handleFilterChange(event, 'name')}
                            label="Name"
                            value={filters.name} 
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.office)}
                            options={uniqueOffices}
                            onChange={event => handleFilterChange(event, 'office')}
                            label="Office"
                            value={filters.office}
                        />
                        {/*
                        <FilterSelect
                            //options={jobs.map(job => job.department)}
                            options={uniqueDepartments}
                            onChange={event => handleFilterChange(event, 'department')}
                            label="Department"
                            value={filters.department}
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.recruitingCategory)}
                            options={uniqueRecruitingCategories}
                            onChange={event => handleFilterChange(event, 'recruitingCategory')}
                            label="Recruiting Category"
                            value={filters.recruitingCategory}
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.employmentType)}
                            options={uniqueEmploymentTypes}
                            onChange={event => handleFilterChange(event, 'employmentType')}
                            label="Employment Type"
                            value={filters.employmentType}
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.seniority)}
                            options={uniqueSeniorities}
                            onChange={event => handleFilterChange(event, 'seniority')}
                            label="Seniority"
                            value={filters.seniority}
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.schedule)}
                            options={uniqueSchedules}
                            onChange={event => handleFilterChange(event, 'schedule')}
                            label="Schedule"
                            value={filters.schedule}
                        />
                        <FilterSelect
                            //options={jobs.map(job => job.yearsOfExperience)}
                            options={uniqueYearsOfExperience}
                            onChange={event => handleFilterChange(event, 'yearsOfExperience')}
                            label="Years of Experience"
                            value={filters.yearsOfExperience}
                        />*/}
                </div>
                <button className='bgcolor-yellow w-16 text-[8px] xs:w-24 rounded-full xs:text-xs font-normal text-black py-2' onClick={resetFilters}>Reset Filters</button>
            </div>
            <div className='flex flex-col sm:flex-row sm:flex-wrap justify-center align-middle gap-5 mb-10'>
                {currentJobs.map && currentJobs.map( (job, index) =>(
                <>
                <div
                key={job.id}
                className={`flex flex-col items-center justify-between gap-4 pearl w-[250px]  sm:w-[200px] text-sm sm:text-md ${getColorClass(index)} `} >
                    <div className=''>
                        <div className="text-center">
                            <p className="font-bold">{job.name}</p>
                            <div className='flex flex-row gap-2 justify-center py-2'>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.office)}}></p>
                                {/*<p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.department)}}></p>*/}
                            </div>
                            {/*<div className='flex flex-row gap-2 justify-center py-2'>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.schedule)}}></p>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.employmentType)}}></p>
                            </div>
                            <div className='flex flex-row gap-2 justify-center py-2'>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.seniority)}}></p>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.yearsOfExperience)}}></p>
                            </div>*/}
                            {/*<div className="flex flex-row gap-2 flex-wrap justify-center text-black py-2">
                            {Array.isArray(job.keywords) && job.keywords.map((keyword, kIndex) => (
                                <span key={kIndex} className={`font-light text-[9px] xs:text-xs border-1 p-1 xs:-p2 rounded-full ${getBgClass(kIndex)}`}>
                                    {keyword}
                                </span>
                            ))}
                            </div>*/}
                            {/*<div className='flex flex-col items-center justify-center text-[9px] xs:text-xs italic xs:py-2 xs:px-2'>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.occupation)}}></p>
                                <p className="font-light" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(job.occupationCategory)}}></p>
                            </div>*/}
                        </div>
                    </div>
                    <div className='py-2'>
                        <a href={`https://seedtag-1.jobs.personio.com/job/${job.id}?language=en&display=en`} target='blank' className='bgcolor-yellow w-24 sm:w-36 rounded-full text-xs font-normal text-black p-2' /*onClick={() => handleShowModal(job)}*/ >Know More</a>
                    </div>
                </div>
                </>
                ) 
                )}
            </div>
            {totalPages > 1 && (
            <div className='flex justify-center text-white items-center gap-4 mb-20'>
                <button className='border border-white p-2 rounded-full w-20 text-sm' onClick={prevPage} disabled={currentPage === 1}>Prev</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button className='border border-white p-2 rounded-full w-20 text-sm' onClick={nextPage} disabled={currentPage === totalPages}>Next</button>
            </div>  
            )}
        </div>
    </div>
    {showModal && (
    <ModalOffer
        data={selectedOffer}
        hasLink={hasLink}
        setShowModal={setShowModal}
        showModal={showModal}
    />
    )}
    </>
    );
}

export default Jobs2;
